import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import PostItem from '../components/PostItem';
import useTranslations from '../components/useTranslations';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';

//import * as S from '../components/ListWrapper/styled';
import '../styles/styles.scss';

const Index = ({ data: { allMarkdownRemark } }) => {
  // useTranslations is aware of the global context (and therefore also "locale")
  // so it'll automatically give back the right translations

  const postList = allMarkdownRemark.edges;

  return (
    <Grid>
      <SEO title="Home" />
      <div className="project-list">
        <Row>
          {postList.map(
            ({
              node: {
                frontmatter: {
                  background,
                  category,
                  date,
                  description,
                  title,
                  image,
                },
                timeToRead,
                fields: { slug },
              },
            }) => (
              <Col md={4} sm={6} xs={12}>
                <div className="project-list__item">
                  <PostItem
                    slug={`/project/${slug}`}
                    background={background}
                    category={category}
                    date={date}
                    timeToRead={timeToRead}
                    title={title}
                    description={description}
                    image={image}
                  />
                </div>
              </Col>
              ),
          )}
        </Row>
      </div>
    </Grid>
  );
};

export default Index;

export const query = graphql`
  query Index($locale: String!, $dateFormat: String!, ) {
    allMarkdownRemark(
      filter: {
        fields: { locale: { eq: $locale } }
        fileAbsolutePath: {regex: "/(blog)\/.*\\.md$/"}
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            category
            background
            image
            date(formatString: $dateFormat)

          }
          timeToRead
          fields {
            locale
            slug
          }
        }
      }
    }
  }
`;
